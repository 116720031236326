import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
} from "@material-ui/core";
import { floatNumberWithCommas, sliceTo10Digits } from "../../../../utils/main";
import Moment from "react-moment";
import axios from "axios";

const TabRow = ({ item, getData, sec }) => {
  const { id, name, txid, amount, from, to, tronscan_date } = item;

  const [isOpen, setIsOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNameChange = async (e) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://ttsx-server.tetherland.app/updateName?name=${newName}&id=${id}&sec=${sec}`,
      );

      if (response.data.status === 1) {
        setNewName("");
        setIsOpen(false);
      }
      setLoading(false);
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TableRow key={id}>
      <TableCell className="pl-3 fw-normal">
        <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
          {name ? name : "NULL"}
        </div>
        {isOpen && (
          <>
            <input
              placeholder={name}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <div style={{ display: "flex", marginTop: 4 }}>
              <button
                style={{
                  background: "green",
                  color: "white",
                  marginRight: 8,
                  paddingLeft: 4,
                  paddingRight: 4,
                  cursor: "pointer",
                }}
                onClick={handleNameChange}
                disabled={loading}
              >
                {loading ? "loading" : "Save"}
              </button>
              <div
                style={{
                  background: "#f44",
                  color: "white",
                  paddingLeft: 4,
                  paddingRight: 4,
                  cursor: "pointer",
                }}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </div>
            </div>
          </>
        )}
      </TableCell>
      <TableCell>
        <a
          style={{ textDecoration: "none", color: "#33f" }}
          href={`https://tronscan.org/#/address/${from}/transactions`}
          target="_blank"
        >
          {sliceTo10Digits(from)}
        </a>
      </TableCell>
      <TableCell>
        <a
          style={{ textDecoration: "none", color: "#33f" }}
          href={`https://tronscan.org/#/address/${to}/transactions`}
          target="_blank"
        >
          {sliceTo10Digits(to)}
        </a>
      </TableCell>

      <TableCell>
        <a
          style={{ textDecoration: "none", color: "#33f" }}
          href={`https://tronscan.org/#/transaction/${txid}`}
          target="_blank"
        >
          {sliceTo10Digits(txid)}
        </a>
      </TableCell>
      <TableCell>{floatNumberWithCommas(amount / 1000000)}</TableCell>
      <TableCell>
        <Moment format="YYYY/MM/DD, hh:mm:ss" unix>
          {tronscan_date / 1000}
        </Moment>
        {/* <Moment format="YYYY/MM/DD hh:mm" unix>
                1633435851
              </Moment> */}
      </TableCell>
      <TableCell>
        <Chip
          label={
            to === "TRiZusJRetjg36uaxz68MP7ofKvNhejHwL" ||
            to === "TJCCkJoy9NfVs5cAhjfkHG3UC3oUPJV3tP" ||
            to === "TN4X5bVU12BFThVhxt6wZjV2cntNHVPqh5" ||
            to === "TRbQ1xFeK1QpbuMi3inihfeiim4X2kx6Qy" ||
            to === "TFeyhL9qQ4wWYNCGpEPGRZj35LUNbKZRjp" ||
            to === "TTqKPkkTVvbVbQJhbPwHUrY8UrU4i7Bwx6" ||
            to === "TMFvLTQnhHyjQ8s8DR3FTLVxDFMeH9X21A" ||
            to === "TKBT4SVTU3S5iDqzhySEzV2Majc9YT5Sm3" ||
            to === "THfLzkeJqdDoN7znvaRRga1evC7epWLjNL"
              ? "IN"
              : "OUT"
          }
          color={
            to === "TRiZusJRetjg36uaxz68MP7ofKvNhejHwL" ||
            to === "TJCCkJoy9NfVs5cAhjfkHG3UC3oUPJV3tP" ||
            to === "TN4X5bVU12BFThVhxt6wZjV2cntNHVPqh5" ||
            to === "TRbQ1xFeK1QpbuMi3inihfeiim4X2kx6Qy" ||
            to === "TFeyhL9qQ4wWYNCGpEPGRZj35LUNbKZRjp" ||
            to === "TTqKPkkTVvbVbQJhbPwHUrY8UrU4i7Bwx6" ||
            to === "TMFvLTQnhHyjQ8s8DR3FTLVxDFMeH9X21A" ||
            to === "TKBT4SVTU3S5iDqzhySEzV2Majc9YT5Sm3" ||
            to === "THfLzkeJqdDoN7znvaRRga1evC7epWLjNL"
              ? "primary"
              : "secondary"
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default function TableComponent({ data, getData, sec }) {
  var keys = Object.keys(data[0]).map((i) => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map((key) => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => {
          return <TabRow getData={getData} item={item} sec={sec} />;
        })}
      </TableBody>
    </Table>
  );
}
